
import { Vue, Component, Watch, Model } from 'vue-property-decorator';
import { BASE_URL } from '@/common/constant';
import { $delay } from '@/common/utils';
import draggable from 'vuedraggable';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { ISPUImageUploadOptions } from '@/common/interface/spu';

interface IImage {
  id: string;
  url: string;
  cover?: string;
  order?: number;
}

@Component({
  components: {
    draggable,
  },
})
export default class ShopImagesField extends Vue {
  watchable = true;

  BASE_URL = BASE_URL;

  token: string | undefined = undefined;

  val: IImage[] = [];

  viewImageSource = '';

  viewImageVisible = false;

  uploading = false;

  /** 上传配置 */
  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  @Model('change')
  value!: IImage[];

  @Watch('val')
  watchInternalVal(): void {
    this.$emit(
      'change',
      this.val.map((item) => item.id),
    );
    this.$emit('updateSubmittable', this.val.length !== 0);
  }

  @Watch('value')
  watchValue() {
    if (!this.watchable) {
      return;
    }
    if (this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  @lazyInject(UserService)
  userService!: UserService;

  beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.$message.error('请上传 png/jpeg 格式的照片!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      this.$message.error('照片必须小于5MB!');
    }
    return isJpgOrPng && isLt5M;
  }

  fileSuccess(rootFile: File, file: File, json: string) {
    this.val.push(JSON.parse(json).data[0]);
  }

  async fileError(rootFile: File, file: File, json: string) {
    const { code, error } = JSON.parse(json);
    if (code === 400) {
      this.$message.error(error);
      await $delay(1000);
      this.$router.push({
        path: '/login',
        query: {
          redirect: this.$route.fullPath,
        },
      });
    } else {
      this.$message.error(error);
    }
  }

  viewImage(src: string) {
    this.viewImageSource = src;
    this.viewImageVisible = true;
  }

  deleteImage(ev: Event, index: number) {
    // eslint-disable-next-line no-param-reassign
    ev.cancelBubble = true;
    this.val.splice(index + 1, 1);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onUploadChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      const result = {
        id: data[0]?.id,
        url: data[0]?.url,
      };
      this.val.splice(0, 1, result);
      this.uploading = false;
    }
  }

  mounted() {
    this.watchValue();
    this.watchable = false;
  }

  created() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }
}
