/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { injectable, inject } from 'inversify';
import StorageService from './storage.service';
import User from '@/models/user';
/**
 *
 *
 * @export
 * @class UserService
 */
@injectable()
export default class UserService {
  @inject(StorageService)
  protected storage!: StorageService;

  currentUser(): User | null {
    const userData = this.storage.get('user');
    if (userData == null) {
      return null;
    }
    return UserService.fromUser(userData);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromUser(data: any): User {
    const user = new User();
    user.id = data.id;
    user.name = data.name;
    user.phone = data.phone;
    user.token = data.token;
    user.appToken = data.appToken;

    return user;
  }

  save(data: User) {
    this.storage.set('user', UserService.fromUser(data));
  }

  public hasLogin(): boolean {
    const user = this.currentUser();
    return user !== null;
  }

  logout() {
    this.storage.remove('user');
    this.storage.remove('permission');
    // 跳转至登陆页
    window.location.hash = '#/login';
  }
}
