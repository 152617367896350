import { ESPUField } from '@/common/enum/spu';
import { ISPUField } from '@/common/interface/spu';
import _ from 'lodash';

export class SPUFieldFactory {
  static init(params: {
    spuType: 'spu' | 'platformSpu' | 'kaSpu' | 'leadingSpu';
    originalPriceValidator?: (
      rule: { [key: string]: string | (() => boolean) },
      value: string,
    ) => boolean;
    vipPriceValidator?: (
      rule: { [key: string]: string | (() => boolean) },
      value: string,
    ) => boolean;
    newUserPriceValidator?: (
      rule: { [key: string]: string | (() => boolean) },
      value: string,
    ) => boolean;

    tyreSpecValidator?: (
      rule: { [key: string]: string | (() => boolean) },
      value: string,
    ) => boolean;
  }) {
    let spuFields: ISPUField[] = [];
    const {
      spuType,
      originalPriceValidator,
      vipPriceValidator,
      newUserPriceValidator,
      tyreSpecValidator,
    } = params;
    if (spuType === 'spu') {
      spuFields = _.cloneDeep(InitSpuFields);
    } else if (spuType === 'platformSpu') {
      spuFields = _.cloneDeep(InitPlatformSpuFields);
    } else if (spuType === 'kaSpu') {
      spuFields = _.cloneDeep(InitKASpuFields);
    } else if (spuType === 'leadingSpu') {
      spuFields = _.cloneDeep(InitLeadingSpuFields);
    }
    const originalPriceIdx = spuFields.findIndex(
      (item) => item.label === ESPUField.ORIGINAL_PRICE_CENT,
    );
    const vipPriceIdx = spuFields.findIndex(
      (item) => item.label === ESPUField.VIP_PRICE_CENT,
    );
    const newUserPriceIdx = spuFields.findIndex(
      (item) => item.label === ESPUField.NEW_USER_PRICE_CENT,
    );
    const tyreSpecIdx = spuFields.findIndex(
      (item) => item.label === ESPUField.TYRE_SPEC,
    );
    spuFields[originalPriceIdx].rule?.push({
      validator: originalPriceValidator,
      message: '门市价必须大于0',
      trigger: 'change',
    });
    spuFields[vipPriceIdx].rule?.push({
      validator: vipPriceValidator,
      message: '黑卡价必须小于门市价或服务价格明细总价',
      trigger: 'change',
    });
    spuFields[newUserPriceIdx].rule?.push({
      validator: newUserPriceValidator,
      message: '新用户特价必须小于黑卡价',
      trigger: 'change',
    });
    if (tyreSpecIdx > -1 && tyreSpecValidator !== undefined) {
      spuFields[tyreSpecIdx].rule = [
        {
          validator: tyreSpecValidator,
          message: '轮胎尺寸信息格式不合法，请参照示例重新输入',
          trigger: 'blur',
        },
      ];
    }
    return spuFields;
  }

  static numberValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    return !isNaN(+value);
  }

  static categoryValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: any,
  ) {
    return value != null && value.id !== 0;
  }
}

/** 普通SPU 字段配置项 */
const InitSpuFields: ISPUField[] = [
  {
    label: ESPUField.ID,
    labelName: '商品ID',
    readonly: true,
  },
  {
    label: ESPUField.CATEGORY,
    labelName: '所属分类',
    placeholder: '请选择分类',
    rule: [
      { required: true, message: '请选择分类', trigger: 'blur' },
      {
        validator: SPUFieldFactory.categoryValidator,
        message: '请选择分类',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.HEADLINE_IMAGE,
    labelName: '商品照片',
    extra: '可上传自定义照片',
    fieldOpts: {
      max: 1,
    },
  },
  {
    label: ESPUField.IS_COMBO,
    labelName: '套餐服务',
  },
  {
    label: ESPUField.EFFECTIVE_TIMES,
    labelName: '可使用次数',
    placeholder: '套餐服务时可使用次数必须大于一次',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
      {
        validator: (
          rule: { [key: string]: string | (() => boolean) },
          value: string,
        ) => {
          return +value % 1 === 0;
        },
        message: '请输入整数',
        trigger: 'change',
      },
    ],
  },
  {
    label: ESPUField.NAME,
    labelName: '服务名称',
    placeholder: '请输入服务名称',
    rule: [{ required: true, message: '请输入服务名称', trigger: 'blur' }],
  },
  {
    label: ESPUField.PRODUCTS,
    labelName: '服务价格明细',
  },
  {
    label: ESPUField.ORIGINAL_PRICE_CENT,
    labelName: '门市价(元)',
    rule: [
      { required: true, message: '请输入门市价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.VIP_PRICE_CENT,
    labelName: '黑卡价(元)',
    rule: [
      { required: true, message: '请输入黑卡价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
    extra: '',
    fieldOpts: {
      extraStyle: 'color:red',
    },
  },
  {
    label: ESPUField.HAS_NEW_USER_PRICE,
    labelName: '新用户特价(元)',
    extra: '首次购买专享',
  },
  {
    label: ESPUField.NEW_USER_PRICE_CENT,
    labelName: '特价(元)',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.COMBO_TOTAL_PRICE,
    labelName: '套餐总价',
  },
  {
    label: ESPUField.IS_HOT,
    labelName: '设为热门',
  },
  {
    label: ESPUField.UNAVAILABLE_TIME,
    labelName: '不可用时段',
  },
  {
    label: ESPUField.INTRODUCTION,
    labelName: '服务详情',
  },
  {
    label: ESPUField.IMAGES,
    labelName: '商品相册',
    extra:
      '拖拽照片后保存以调整顺序（相册图片尺寸：定宽640/750px， 高控制在宽 X 9以内 ）',
  },
];
/** 平台标品SPU 字段配置项 */
const InitPlatformSpuFields: ISPUField[] = [
  {
    label: ESPUField.ID,
    labelName: '商品ID',
    readonly: true,
  },
  {
    label: ESPUField.CATEGORY,
    labelName: '所属分类',
    placeholder: '请选择分类',
    rule: [
      { required: true, message: '请选择分类', trigger: 'blur' },
      {
        validator: SPUFieldFactory.categoryValidator,
        message: '请选择分类',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.VIDEOS,
    labelName: '标品视频',
  },
  {
    label: ESPUField.HEADLINE_IMAGES,
    labelName: '标品头图',
    extra: '第一张将设置为主图',
  },
  {
    label: ESPUField.HEADLINE_IMAGE,
    labelName: '商品照片',
    extra: '可上传自定义照片',
    fieldOpts: {
      max: 1,
    },
    isHidden: true,
  },
  {
    label: ESPUField.IS_COMBO,
    labelName: '套餐服务',
  },
  {
    label: ESPUField.EFFECTIVE_TIMES,
    labelName: '可使用次数',
    placeholder: '套餐服务时可使用次数必须大于一次',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
      {
        validator: (
          rule: { [key: string]: string | (() => boolean) },
          value: string,
        ) => {
          return +value % 1 === 0;
        },
        message: '请输入整数',
        trigger: 'change',
      },
    ],
  },
  {
    label: ESPUField.NAME,
    labelName: '服务名称',
    placeholder: '请输入服务名称',
    rule: [{ required: true, message: '请输入服务名称', trigger: 'blur' }],
  },
  {
    label: ESPUField.PRODUCTS,
    labelName: '服务价格明细',
  },
  {
    label: ESPUField.ORIGINAL_PRICE_CENT,
    labelName: '门市价(元)',
    rule: [
      { required: true, message: '请输入门市价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.VIP_PRICE_CENT,
    labelName: '黑卡价(元)',
    rule: [
      { required: true, message: '请输入黑卡价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
    extra: '',
    fieldOpts: {
      extraStyle: 'color:red',
    },
  },
  {
    label: ESPUField.HAS_NEW_USER_PRICE,
    labelName: '新用户特价(元)',
    extra: '首次购买专享',
  },
  {
    label: ESPUField.NEW_USER_PRICE_CENT,
    labelName: '特价(元)',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.COMBO_TOTAL_PRICE,
    labelName: '套餐总价',
  },
  {
    label: ESPUField.IS_HOT,
    labelName: '设为热门',
  },
  {
    label: ESPUField.UNAVAILABLE_TIME,
    labelName: '不可用时段',
  },
  {
    label: ESPUField.INTRODUCTION,
    labelName: '服务详情',
  },
  {
    label: ESPUField.IMAGES,
    labelName: '商品相册',
    extra:
      '拖拽照片后保存以调整顺序（相册图片尺寸：定宽640/750px， 高控制在宽 X 9以内 ）',
  },
  {
    label: ESPUField.TEMP_SPU_SHOP_POIS,
    labelName: '适用门店',
    rule: [{ required: true, message: '适用门店不能为空', trigger: 'blur' }],
  },
  {
    label: ESPUField.SHORT_COMMENT,
    labelName: '推荐理由',
    rule: [{ required: true, message: '请填写商品短评论', trigger: 'blur' }],
  },
  {
    label: ESPUField.IS_DRAW_COMMISSION,
    labelName: '是否分佣',
  },
];
/** 品牌标品SPU 字段配置项 */
const InitKASpuFields: ISPUField[] = [
  {
    label: ESPUField.KA,
    labelName: 'KA',
    placeholder: '请选择KA',
    rule: [{ required: true, message: '请选择KA', trigger: 'blur' }],
  },
  {
    label: ESPUField.ID,
    labelName: '商品ID',
    readonly: true,
  },
  {
    label: ESPUField.CATEGORY,
    labelName: '所属分类',
    placeholder: '请选择分类',
    rule: [
      { required: true, message: '请选择分类', trigger: 'blur' },
      {
        validator: SPUFieldFactory.categoryValidator,
        message: '请选择分类',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.VIDEOS,
    labelName: '标品视频',
  },
  {
    label: ESPUField.HEADLINE_IMAGES,
    labelName: '标品头图',
    extra: '可上传自定义照片，第一张将设置成标品主图',
  },
  {
    label: ESPUField.HEADLINE_IMAGE,
    labelName: '商品照片',
    extra: '可上传自定义照片',
    fieldOpts: {
      max: 1,
    },
    isHidden: true,
  },
  {
    label: ESPUField.IS_COMBO,
    labelName: '套餐服务',
  },
  {
    label: ESPUField.EFFECTIVE_TIMES,
    labelName: '可使用次数',
    placeholder: '套餐服务时可使用次数必须大于一次',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
      {
        validator: (
          rule: { [key: string]: string | (() => boolean) },
          value: string,
        ) => {
          return +value % 1 === 0;
        },
        message: '请输入整数',
        trigger: 'change',
      },
    ],
  },
  {
    label: ESPUField.NAME,
    labelName: '服务名称',
    placeholder: '请输入服务名称',
    rule: [{ required: true, message: '请输入服务名称', trigger: 'blur' }],
  },
  {
    label: ESPUField.PRODUCTS,
    labelName: '服务价格明细',
  },
  {
    label: ESPUField.ORIGINAL_PRICE_CENT,
    labelName: '门市价(元)',
    rule: [
      { required: true, message: '请输入门市价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.VIP_PRICE_CENT,
    labelName: '黑卡价(元)',
    rule: [
      { required: true, message: '请输入黑卡价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
    extra: '',
    fieldOpts: {
      extraStyle: 'color:red',
    },
  },
  {
    label: ESPUField.HAS_NEW_USER_PRICE,
    labelName: '新用户特价(元)',
    extra: '首次购买专享',
  },
  {
    label: ESPUField.NEW_USER_PRICE_CENT,
    labelName: '特价(元)',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.COMBO_TOTAL_PRICE,
    labelName: '套餐总价',
  },
  {
    label: ESPUField.IS_HOT,
    labelName: '设为热门',
  },
  {
    label: ESPUField.UNAVAILABLE_TIME,
    labelName: '不可用时段',
  },
  {
    label: ESPUField.INTRODUCTION,
    labelName: '服务详情',
  },
  {
    label: ESPUField.IMAGES,
    labelName: '商品相册',
    extra:
      '拖拽照片后保存以调整顺序（相册图片尺寸：定宽640/750px， 高控制在宽 X 9以内 ）',
  },
  {
    label: ESPUField.TEMP_SPU_SHOP_IDS,
    labelName: '适用门店',
    placeholder:
      '请输入或导入适用门店德友惠ID，若输入多个请以英文逗号“,” 进行分割',
    rule: [{ required: true, message: '适用门店不能为空', trigger: 'blur' }],
  },
  {
    label: ESPUField.TYRE_TAG,
    labelName: '轮胎性能',
    placeholder: '请选择轮胎性能',
    rule: [{ required: true, message: '请选择轮胎性能', trigger: 'blur' }],
    isHidden: true,
  },
  {
    label: ESPUField.TYRE_SPEC,
    labelName: '轮胎尺寸',
    extra: '示例：225/55R20',
    isHidden: true,
  },
  {
    label: ESPUField.SHORT_COMMENT,
    labelName: '推荐理由',
    rule: [{ required: true, message: '请填写商品短评论', trigger: 'blur' }],
  },
  {
    label: ESPUField.IS_DRAW_COMMISSION,
    labelName: '是否分佣',
    isHidden: true,
  },
  {
    label: ESPUField.COMMISSION_RATIO,
    labelName: '抽拥比列',
  },
];
/** 批量操作多门店SPU 字段配置项 */
const InitLeadingSpuFields: ISPUField[] = [
  {
    label: ESPUField.ID,
    labelName: '商品ID',
    readonly: true,
  },
  {
    label: ESPUField.CATEGORY,
    labelName: '所属分类',
    placeholder: '请选择分类',
    rule: [
      { required: true, message: '请选择分类', trigger: 'blur' },
      {
        validator: SPUFieldFactory.categoryValidator,
        message: '请选择分类',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.HEADLINE_IMAGE,
    labelName: '商品照片',
    extra: '可上传自定义照片',
    fieldOpts: {
      max: 1,
    },
  },
  {
    label: ESPUField.IS_COMBO,
    labelName: '套餐服务',
  },
  {
    label: ESPUField.EFFECTIVE_TIMES,
    labelName: '可使用次数',
    placeholder: '套餐服务时可使用次数必须大于一次',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
      {
        validator: (
          rule: { [key: string]: string | (() => boolean) },
          value: string,
        ) => {
          return +value % 1 === 0;
        },
        message: '请输入整数',
        trigger: 'change',
      },
    ],
  },
  {
    label: ESPUField.NAME,
    labelName: '服务名称',
    placeholder: '请输入服务名称',
    rule: [{ required: true, message: '请输入服务名称', trigger: 'blur' }],
  },
  {
    label: ESPUField.PRODUCTS,
    labelName: '服务价格明细',
  },
  {
    label: ESPUField.ORIGINAL_PRICE_CENT,
    labelName: '门市价(元)',
    rule: [
      { required: true, message: '请输入门市价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.VIP_PRICE_CENT,
    labelName: '黑卡价(元)',
    rule: [
      { required: true, message: '请输入黑卡价', trigger: 'blur' },
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
    extra: '',
    fieldOpts: {
      extraStyle: 'color:red',
    },
  },
  {
    label: ESPUField.HAS_NEW_USER_PRICE,
    labelName: '新用户特价(元)',
    extra: '首次购买专享',
  },
  {
    label: ESPUField.NEW_USER_PRICE_CENT,
    labelName: '特价(元)',
    rule: [
      {
        validator: SPUFieldFactory.numberValidator,
        message: '请输入数字',
        trigger: 'blur',
      },
    ],
  },
  {
    label: ESPUField.COMBO_TOTAL_PRICE,
    labelName: '套餐总价',
  },
  {
    label: ESPUField.IS_HOT,
    labelName: '设为热门',
  },
  {
    label: ESPUField.UNAVAILABLE_TIME,
    labelName: '不可用时段',
  },
  {
    label: ESPUField.INTRODUCTION,
    labelName: '服务详情',
  },
  {
    label: ESPUField.IMAGES,
    labelName: '商品相册',
    extra:
      '拖拽照片后保存以调整顺序（相册图片尺寸：定宽640/750px， 高控制在宽 X 9以内 ）',
  },
  {
    label: ESPUField.TEMP_SPU_SHOP_IDS,
    labelName: '适用门店',
    placeholder:
      '请输入或导入适用门店德友惠ID，若输入多个请以英文逗号“,” 进行分割',
    rule: [{ required: true, message: '适用门店不能为空', trigger: 'blur' }],
  },
  {
    label: ESPUField.IS_AUTO_OFF_SHELF,
    labelName: '自动下架',
  },
  {
    label: ESPUField.AUTO_OFF_SHELF_TIME,
    labelName: '自动下架时间',
    placeholder: '请选择自动下架时间：年月日时分，且至少设置在10分钟以后',
    isHidden: true,
  },
  {
    label: ESPUField.FILTER_TAG,
    labelName: '筛选标签',
  },
  {
    label: ESPUField.REMAK,
    labelName: '备注',
    placeholder: '请输入该商品的备注信息仅用于管理该商品时的信息记录',
  },
];
